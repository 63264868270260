type TSlider = {
    descriptionKey: string;
    imgNumber: number;
};

export const SLIDER: TSlider[] = [
    {
        descriptionKey:
            "step1Descr",
        imgNumber: 1,
    },
    {
        descriptionKey: "step2Descr",
        imgNumber: 2,
    },
    {
        descriptionKey:
            "step3Descr",
        imgNumber: 3,
    },
    {
        descriptionKey: "step4Descr",
        imgNumber: 4,
    },
    {
        descriptionKey:
            "step5Descr",
        imgNumber: 5,
    },
];
