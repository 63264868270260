import React, { useRef, useState } from "react";
import { classNames } from "src/components/utils/classess";

export const ToggleItem: React.FC<{
    name: string;
    answer: string;
    isVisible?: boolean;
}> = ({ name, answer, isVisible = true }) => {
    const [active, setActive] = useState(false);

    const contentEl = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setActive((prev) => !prev);
    };

    return isVisible ? (<>
        <div className="toggle-item">
            <div
                className={classNames(
                    "toggle-item__tab-title",
                    active && "active",
                )}
                onClick={handleToggle}
            >
                <h3>{name}</h3>
                <>
                    {active ? (
                        <i className="pi pi-minus" />
                    ) : (
                        <i className="pi pi-plus" />
                    )}
                </>
            </div>
            <div
                className={classNames("toggle-item__tab-content")}
                style={
                    active
                        ? { height: contentEl.current?.scrollHeight }
                        : { height: "0px" }
                }
                ref={contentEl}
            >
                <p dangerouslySetInnerHTML={{ __html: answer }} />
            </div>
        </div>
        <hr/>
    </>) : null;
};
