import React, { useEffect, useRef, useState } from "react";
import {
    MENU_LIST_DESKTOP,
    MENU_LIST_MOBILE,
} from "src/components/nav/const_menu";
import { NavItem } from "src/components/nav/nav_item";
import "./nav.styles.scss";
import { Hamburger } from "../UI/buttons/hamburger";
import { classNames } from "../utils/classess";
import { useDetectClick } from "src/hooks/useDetectClick";
import { useNavigation } from "./useNavigation";
import ReactDOM from "react-dom";
import useViewport from "src/hooks/useViewport";
import { useTranslation } from "react-i18next";
import { LanguageSwitch } from "../UI/languageSwitch/languageSwitch";
import { getLanguages } from "src/localization/locales";
import {getLocale} from "../../redux/selectors";
import {useSelector} from "react-redux";

export const Nav: React.FC = () => {
    const navigate = useNavigation();
    const menuRef = useRef(null);
    const refButton = useRef(null);
    const { isActive, setActive, setClose } = useDetectClick(menuRef, refButton);
    const { width } = useViewport();
    const { t }  = useTranslation();
    const locale = useSelector(getLocale);

    return (
        <div className="menu" ref={refButton}>
            {width < 768 && <Hamburger active={isActive} onClick={setActive} />}
            {width < 768 && isActive
                ? ReactDOM.createPortal(
                    <nav
                        className={classNames(
                            "mobile-navigation",
                            isActive && "active",
                        )}
                        ref={menuRef}
                    >
                        <ul className={classNames("menu__items")}>
                            {MENU_LIST_MOBILE.map(
                                (menu, index: number) => {
                                    return (
                                        <NavItem
                                            href={menu.appendLocale ? menu.href + "?lang=" + locale : menu.href}
                                            text={t(menu.textKey || '') || ''}
                                            key={index}
                                            onClick={setActive}
                                            active={navigate.detect(
                                                menu.href,
                                            )}
                                            newPage={menu.newPage}
                                            className={menu.className}
                                        />
                                    );
                                },
                            )
                            }
                            {
                                <li><LanguageSwitch languages={getLanguages()} isMobile={true} onSelect={() => setClose()}/></li>
                            }
                        </ul>
                    </nav>,
                    document.body,
                )
                : null}
            {width > 768 &&
                <nav>
                    <ul className={classNames("menu__items")}>
                        {MENU_LIST_DESKTOP.map((menu, index: number) => {
                            return (
                                <NavItem
                                    href={menu.appendLocale ? menu.href + "?lang=" + locale : menu.href}
                                    text={t(menu.textKey || '') || ''}
                                    key={index}
                                    onClick={setActive}
                                    active={navigate.detect(menu.href)}
                                    newPage={menu.newPage}
                                    className={menu.className}
                                />
                            );
                        })}
                    </ul>
                </nav>
            }
        </div>
    );
};
