import { Route, Routes } from "react-router-dom";
import React from "react";
import { Layout } from "../components/layout/_layout";
import * as Pages from "src/pages";
import * as link from "./const_routes";
import { getLocales } from "../localization/locales";
import { LocalizedRedirect } from "../localization/components/LocalizedRedirect";

export const MyRoutes: React.FC = () => {
    const localeKeys = Object.keys(getLocales());

    return (
        <Routes>
            {localeKeys.map(localeKey => {
                const parentRoutePath = `${link.HOME_PATH}${localeKey}`;

                return <Route path={`${parentRoutePath}`} element={<Layout />} key={localeKey}>
                    <Route index element={<Pages.Home />} />
                    <Route path={`${link.PRINT_PATH}`} element={<Pages.BrandBooks />} />
                    <Route path={`${link.PRIVACY_POLICY_PATH}`} element={<Pages.PrivacyPolicy />} />
                    <Route path={`*`} element={<Pages.Home />}></Route>
                </Route>
            })}
            <Route path="*" element={<LocalizedRedirect />}></Route>
        </Routes>
    );
};
