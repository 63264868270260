import React, {CSSProperties} from "react";
import styles from './spinner.module.scss'

interface SpinnerProps {
    style?: CSSProperties | undefined;
}

export const Spinner: React.FC<SpinnerProps> = ({...props}) => {
    return <div className={styles.rhombus} style={props.style}>
        <div className={styles.child}></div>
        <div className={styles.child}></div>
        <div className={styles.child}></div>
        <div className={styles.child}></div>
        <div className={styles.child}></div>
        <div className={styles.child}></div>
        <div className={styles.child}></div>
        <div className={styles.child}></div>
        <div className={styles.big}></div>
    </div>
}
