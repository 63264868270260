import { LocaleKeys } from "src/localization/locales";
import { PrintImages } from "src/pages/print/img";
import { UPLOADS_LINK } from "../../../constants/link";

type TContentParams = {
    nameKey?: string;
    paramKeys?: string[];
};

export const contentParams: TContentParams[] = [
    {
        nameKey: "format",
        paramKeys: ["A4mm", "A5mm"],
    },
    {
        nameKey: "oneSidePrinting",
        paramKeys: [
            "onSelfAdhesiveFilm",
            "onCoatedPaper",
            "laminationRecommended",
        ],
    },
    {
        nameKey: "archivesInclude",
        paramKeys: [
            "editableLayout",
            "printedLayouts",
            "fonts",
        ],
    },
];

type TDefaultCards = {
    imgKey?: string;
    linkKey?: string;
    paramKeys?: string;
    type?: "default" | "small" | "big";
    double?: TDefaultCards[];
};

export function getLink(linkKey: string, locale: string) {
    return UPLOADS_LINK(`print/${locale}/${linkKey}`);
}

export const contentCards: TDefaultCards[] = [
    {
        imgKey: "staff1",
        paramKeys: "A4mm",
        type: "big",
        linkKey: "qrpos_guide_A4.zip",
    },
    {
        imgKey: "staff2",
        paramKeys: "A5mm",
        linkKey: "qrpos_instruction_A5_01.zip",
    },
    {
        imgKey: "staff3",
        paramKeys: "A5mm",
        type: "big",
        linkKey: "qrpos_instruction_A5_02.zip",
    },
];
