import { ILanguage } from "./ILanguage";

const en = require('../locales/en.json');
const ru = require('../locales/ru.json');
// const tr = require('../locales/tr.json');

export function getLocales() {
    return {
        en: {
            translation: en,
        },
        ru: {
            translation: ru,
        },
       /* tr: {
            translation: tr,
        },*/
    }
}

export const LocaleKeys = {
    en: "en",
    ru: "ru",
 //   tr: "tr",
}

export function getDefaultLocale() {
    return LocaleKeys.en;
    // const languageShort = navigator.language?.split('-')?.at(0)
    //
    // return languageShort || LocaleKeys.ru;
}

export function getLanguages(): ILanguage[]{
    return [
        {
            shortLangName: LocaleKeys.ru,
            longLangName: "Русский",
        },
        {
            shortLangName: LocaleKeys.en,
            longLangName: "English",
        },
      /*  {
            shortLangName: LocaleKeys.tr,
            longLangName: "Türkçe",
        },*/
    ]
}