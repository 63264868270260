import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDefaultLocale, getLocales } from "../locales";

export function LocalizedRedirect() {
    const navigate = useNavigate();
    const location = useLocation();
    const locales = getLocales();

    const defaultLocale = getDefaultLocale();

    useEffect(() => {
        const firstUrlPathPart = location.pathname?.split("/").at(1) || "";
        if (Object.keys(locales).indexOf(firstUrlPathPart) < 0) {
            navigate(`/${defaultLocale}${location.pathname}`);
        }
    }, [defaultLocale, locales]);
    
    return <></>;
}