import React from "react";
import { HeaderBrandBooks } from "./header/_header";
import "./brand_books.styles.scss";
import { ClientBrandBooks } from "./client";
import { StaffBrandBooks } from "./staff";

export const BrandBooks: React.FC = () => {
    return (
        <section className="brand_books-page">
            <HeaderBrandBooks />
            <ClientBrandBooks />
            <StaffBrandBooks />
        </section>
    );
};
