// ru

import client1 from "./polygraphy_for-client_160x230_1.png";
import client2 from "./polygraphy_for-client_160x230_2.png";
import client3 from "./polygraphy_for-client_160x230_3.png";
import client4 from "./polygraphy_for-client_160x230_4.png";
import client5 from "./polygraphy_for-client_160x230_5.png";
import client6 from "./polygraphy_for-client_160x87_1.png";
import client7 from "./polygraphy_for-client_160x87_2.png";
import staff1 from "./polygraphy_for-staff_a4.png";
import staff2 from "./polygraphy_for-staff_a5_1.png";
import staff3 from "./polygraphy_for-staff_a5_2.png";

// en
import client1en from "./en/polygraphy_for-client_160x230_1.png";
import client2en from "./en/polygraphy_for-client_160x230_2.png";
import client3en from "./en/polygraphy_for-client_160x230_3.png";
import client4en from "./en/polygraphy_for-client_160x230_4.png";
import client5en from "./en/polygraphy_for-client_160x230_5.png";
import client7en from "./en/polygraphy_for-client_160x87_2.png";
import staff1en from "./en/polygraphy_for-staff_a4.png";
import staff2en from "./en/polygraphy_for-staff_a5_1.png";
import staff3en from "./en/polygraphy_for-staff_a5_2.png";

export const PrintImages: {[key: string]: string} = {
    client1,
    client2,
    client3,
    client4,
    client5,
    client6,
    client7,
    staff1,
    staff2,
    staff3,
    client1en,
    client2en,
    client3en,
    client4en,
    client5en,
    client7en,
    staff1en,
    staff2en,
    staff3en,
}