import React from "react";
import styles from "./google.module.scss";
import {
    GoogleButton,
    GoogleButtonWhite,
    GoogleButtonWhiteEn,
    GoogleButtonEn,
} from "src/components/UI/icons/svg_icons";
import { GOOGLE_MARKET } from "src/constants/link";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getLocale } from "src/redux/selectors";
import { LocaleKeys } from "src/localization/locales";

function getButton(locale: string, type: "white" | undefined) {
    if (type === "white") {
        switch (locale) {
            case LocaleKeys.en:
                return <GoogleButtonWhiteEn />;
            default:
                return <GoogleButtonWhite />;
        }
    } else {
        switch (locale) {
            case LocaleKeys.en:
                return <GoogleButtonEn />;
            default:
                return <GoogleButton />;
        }
    }
}

export const GooglePlayButton: React.FC<{ type?: "white" }> = ({ type }) => {
    const { t } = useTranslation();
    const locale = useSelector(getLocale);

    return (
        <a href={GOOGLE_MARKET} target={"_blank"} className={styles.google}>
            {getButton(locale, type)}
        </a>
    );
};
