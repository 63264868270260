import React from "react";
import { useTranslation } from "react-i18next";
import { BreadCrumbs } from "src/components/bread_crumbs/_bread_crumbs";

export const HeaderBrandBooks: React.FC = () => {
    const { t } = useTranslation();

    return (
        <BreadCrumbs>
            <h1>{t('printMaterials')}</h1>
            <h2>{t('setOfAdvertisingMaterials')}</h2>
            <h3>{t('advertisingMaterialsUsage')}</h3>
        </BreadCrumbs>
    );
};
