type TAdvantages = {
    nameKey: string;
    descKey: string;
    imgKey: ImageKeys;
};

export type ImageKeys = "easy"
    | "quick"
    | "profitable"
    | "safe"
    | "accessible";

export const ADVANTAGES: TAdvantages[] = [
    {
        nameKey: "simple",
        descKey: "simpleDescr",
        imgKey: "easy",
    },
    {
        nameKey: "quick",
        descKey: "quickDescr",
        imgKey: "quick",
    },
    {
        nameKey: "profitable",
        descKey: "profitableDescr",
        imgKey: "profitable",
    },
    {
        nameKey: "safe",
        descKey: "safeDescr",
        imgKey: "safe",
    },
    {
        nameKey: "handy",
        descKey: "handyDescr",
        imgKey: "accessible",
    },
];
