import React from "react";
import { contentCards, contentParams, getLink } from "./content";

import {
    CardBrandBook,
    CardDoubleBrandBook,
    CardsBrandBook,
} from "src/components/UI/cards/brand_book";
import { useTranslation } from "react-i18next";
import { PrintImages } from "../img";
import { getImageKeyByLocale } from "src/localization/localizationUtils";
import { useSelector } from "react-redux";
import { getLocale } from "src/redux/selectors";


export const StaffBrandBooks: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="brand_books staff">
            <div className="container">
                <h2>{t('instructionsForEmployees')}</h2>
                <div className="board">
                    <Left />
                    <Right />
                </div>
            </div>
        </div>
    );
};

const Left: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="left">
            {contentParams.map((content, index) => {
                return (
                    <ul key={index}>
                        <p>{t(content.nameKey || '')}</p>
                        {content.paramKeys?.map((paramKey, paramID) => (
                            <li key={paramID}>{t(paramKey || '')}</li>
                        ))}
                    </ul>
                );
            })}
        </div>
    );
};

const Right: React.FC = () => {
    const { t } = useTranslation();
    const locale = useSelector(getLocale);

    return (
        <div className="right">
            <CardsBrandBook>
                {contentCards.map((card, index) => {
                    if (card.double) {
                        return (
                            <CardDoubleBrandBook key={index}>
                                {card.double.map((item, doubleID) => (
                                    <CardBrandBook
                                        preview={PrintImages[getImageKeyByLocale(item.imgKey || '', locale)]}
                                        type={item.type}
                                        info={t(item.paramKeys || '') || ''}
                                        link={getLink(item.linkKey || '', locale)}
                                        key={`${index}_${doubleID}`}
                                    />
                                ))}
                            </CardDoubleBrandBook>
                        );
                    }
                    return (
                        <CardBrandBook
                            key={index}
                            preview={PrintImages[getImageKeyByLocale(card.imgKey || '', locale)]}
                            type={card.type}
                            info={t(card.paramKeys || '') || ''}
                            link={getLink(card.linkKey || '', locale)}
                        />
                    );
                })}
            </CardsBrandBook>
        </div>
    );
};
