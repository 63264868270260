import React, { useState } from "react";
import { QUESTIONS } from "./const_faq";
import { ToggleItem } from "./toggle_item";
import "./questions.styles.scss";
import { Button } from "src/components/UI/buttons/button/_button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getLocale } from "../../../../redux/selectors";
import { LocaleKeys } from "src/localization/locales";

export const Faq: React.FC = () => {
    const [showAll, setShowAll] = useState(false);
    const { t } = useTranslation();
    const locale = useSelector(getLocale);

    return (
        <div className="questions" id="faq">
            <div className="container">
                <div className="row">
                    <h2>{t('faq')}</h2>
                </div>
                <hr/>
                <div className="toggle-items">
                    {QUESTIONS
                    .filter(question => {
                        if (locale === LocaleKeys.en && (
                            question.nameKey === 'IsReceiptRequiredQuestion'
                            || question.nameKey === 'howToGetRegistryQuestion'
                            || question.nameKey === 'whatIsSBPQuestion'
                        )) {
                            return false;
                        }
                        return true;
                    })
                    .map((item, idx) => (
                        <ToggleItem
                            name={t(item.nameKey)}
                            answer={t(item.answerKey)}
                            key={idx}
                            isVisible={idx >= 5 ? showAll : true}
                        />
                    ))}
                </div>
                {!showAll ? (
                    <div className="row">
                        <Button
                            className="expand-button"
                            label={t('seeAllAnswersQuestions')}
                            onClick={() =>
                                setShowAll((prevState) => !prevState)
                            }
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
