import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import styles from "./bread_crumbs.module.scss";
import { useSelector } from "react-redux";
import { getLocale } from "../../redux/selectors";
import { useTranslation } from "react-i18next";

type THeader = {
    children: ReactNode;
};
export const BreadCrumbs: React.FC<THeader> = ({ children }) => {
    const locale = useSelector(getLocale);
    const { t } = useTranslation();

    console.log('crumbs'  + locale);
    return (
        <div className={styles.breadCrumbs}>
            <div className="container">
                <div className={styles.breadCrumbs__wrap}>
                    <div className={styles.breadCrumbs__homeLink}>
                        <Link to={`/${locale}`}>
                            <i className="pi pi-angle-left"></i>
                            <span> {t('back')} </span>
                        </Link>
                    </div>
                    <div className={styles.breadCrumbs__info}>{children}</div>
                </div>
            </div>
        </div>
    );
};
