import React from "react";
import { classNames } from "src/components/utils/classess";
import "./button_styles.scss";

export const Button: React.FC<{
    label: string;
    className?: string;
    type?: "submit" | "reset" | "button" | undefined;
    onClick?: Function;
}> = ({ label, className, type, onClick }) => {
    return (
        <div className={classNames("btn", className)}>
            <button type={type} onClick={() => (onClick ? onClick() : null)}>
                {label}
            </button>
        </div>
    );
};
