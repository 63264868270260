import React, { useEffect, useState } from "react";
import { Head } from "../head/_head";
import { Header } from "../header/_header";
import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "../footer/_footer";
import { setUpLocale } from "../../localization/localizationUtils";
import { connect, useDispatch } from "react-redux";
import { IAction } from "src/redux/IAction";
import { Dispatch } from "redux";

export const Layout: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch<Dispatch<IAction>>();
    
    useEffect(() => {
        setUpLocale(location.pathname || '', dispatch);
    }, [location, dispatch]);

    return (
        <>
            <Head />
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    );
};

export default connect(null, {})(Layout);
