import React from "react";
import ReactDOM from "react-dom/client";
import "primeicons/primeicons.css";
import "./global.scss";
import { BrowserRouter } from "react-router-dom";
import { MyRoutes } from "./routes";
import ScrollToTop from "./hooks/useScrollToTop";
import { Provider } from 'react-redux'
import { store } from './redux/store'
import "./i18n";

ReactDOM.createRoot(document.getElementById("app") as HTMLElement).render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
            <ScrollToTop />
            <MyRoutes />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
);
