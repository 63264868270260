import React from "react";
import styles from "./hamburger.module.scss";
import { classNames } from "../../../utils/classess";

export const Hamburger: React.FC<{
    active?: boolean;
    onClick?: Function;
    type?: "type1" | "type2";
}> = ({ active, onClick, type = "type1" }) => {
    const stylesLineSplit = {
        type1: styles.lineSplitType1,
        type2: styles.lineSplitType2,
    };

    return (
        <div
            className={classNames(styles.hamburger, active && styles.active)}
            onClick={() => (onClick ? onClick() : null)}
        >
            <span
                className={classNames(styles.lineMain, active && styles.active)}
            />
            <span
                className={classNames(
                    styles.lineSplit,
                    stylesLineSplit[type],
                    active && styles.active,
                )}
            />
        </div>
    );
};
