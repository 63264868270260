import React, { useEffect, useState } from "react";
import "./feedback.styles.scss";
import { useFormik } from "formik";
import { classNames } from "../utils/classess";
import { useFetch } from "./response";
import ReactDOM from "react-dom";
import { PopupFeedBack } from "./popup/popup";
import { useTranslation } from "react-i18next";

interface FormValue {
    name?: string;
    email?: string;
    message?: string;
}

const emptyValues: FormValue = { name: "", email: "", message: "" };
export const Feedback: React.FC = () => {
    const initialValues: FormValue = emptyValues;
    const { fetch, response, loading } = useFetch();
    const { t } = useTranslation();

    const [popupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        if (response) {
            setPopupVisible(true);
            setTimeout(() => {
                setPopupVisible(false);
            }, 5000);
        }
    }, [response]);

    const formik = useFormik({
        initialValues: initialValues,
        validate: (values) => {
            const errors: FormValue = {};

            if (!values.name) {
                errors.name = "Required";
            }

            if (!values.email) {
                errors.email = "Required";
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
                errors.email = "Invalid email address";
            }

            if (!values.message) {
                errors.message = "Required";
            }

            return errors;
        },

        onSubmit: (values, { setSubmitting, resetForm }) => {
            fetch(values);
            setSubmitting(false);
            resetForm({ values: emptyValues });
        },
    });
    return (
        <>
            <div className="feedback">
                <h3> {t('feedbackForm')}</h3>
                <form onSubmit={formik.handleSubmit}>
                    <div className="container-form">
                        <div className={classNames("input__name")}>
                            <input
                                className={
                                    formik.touched.name && formik.errors.name
                                        ? "error"
                                        : ""
                                }
                                name="name"
                                type="text"
                                placeholder={t('name') || ''}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </div>
                        <div className={classNames("input__email")}>
                            <input
                                className={
                                    formik.touched.email && formik.errors.email
                                        ? "error"
                                        : ""
                                }
                                name="email"
                                type="email"
                                placeholder={t('email') || ''}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                        </div>
                        <div className={classNames("input__message")}>
                            <textarea
                                className={
                                    formik.touched.email &&
                                    formik.errors.message
                                        ? "error"
                                        : ""
                                }
                                name="message"
                                placeholder={t('message') || ''}
                                onChange={formik.handleChange}
                                value={formik.values.message}
                            />
                        </div>
                        <div className="input__submit">
                            <button
                                className={classNames(loading && "loading")}
                                type="submit"
                                disabled={loading}
                            >
                                <span>{t('send')}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {popupVisible &&
                ReactDOM.createPortal(
                    <PopupFeedBack onClose={() => setPopupVisible(false)} />,
                    document.body,
                )}
        </>
    );
};
