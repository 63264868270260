import API from "src/axios/config";
import { useState } from "react";
import axios from "axios";
import { sleep } from "../utils/sleep";

type TValueRequest = {
    name?: string;
    email?: string;
    message?: string;
    type?: string;
};
export const useFetch = () => {
    const [response, setResponse] = useState<boolean | undefined>(undefined);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const fetch = async (value: TValueRequest) => {
        setLoading(true);
        try {
            if (process.env.REACT_APP_HOST_ENV !== "development") {
                if (process.env.REACT_APP_HOST_ENV === "staging") {
                    value.type = "staging";
                }
                const { data } = await API.post(`feed-back`, value);
                setResponse(data.success);
            } else {
                await sleep(1000);
                setResponse(true);
            }
        } catch (err) {
            if (axios.isAxiosError(err)) {
                setError(err.message);
            }
        } finally {
            await sleep(5000);
            setLoading(false);
            setResponse(undefined);
        }
    };

    return { fetch, response, error, loading };
};
