import { ILanguage } from "../../../localization/ILanguage";
import styles from "./languageSwitch.module.scss";
import globeImg from "./images/globe.svg";
import { SyntheticEvent, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { getLocale } from "../../../redux/selectors";
import {useTranslation} from "react-i18next";

export interface IProps {
    languages: ILanguage[];
    isMobile?: boolean;
    onSelect?: () => void;
}

export function LanguageSwitch(props: IProps) {
    const navigate = useNavigate();
    const locale = useSelector(getLocale);
    const { t } = useTranslation();

    const onChange = (event: SyntheticEvent<HTMLSelectElement>) => {
        navigate(`/${(event.target as any)?.value}`);
    };

    const onChangeMobile = useCallback(() => {
        if (props.onSelect) {
            console.log(111)
            props.onSelect();
        }

    }, [props.onSelect]);

    if (props.isMobile) {
        return <div className={styles.rootMobile}>
            <div>{t("changeLanguage")}</div>
            <div className={styles.languagesMobileContainer}>
                {props.languages.map(lang => <Link
                    aria-label={lang.longLangName}
                    title={lang.longLangName}
                    key={lang.shortLangName}
                    to={`/${lang.shortLangName}`}
                    onClick={() => onChangeMobile()}
                    >
                    {lang.shortLangName.toLocaleUpperCase()}</Link>
                )}
            </div>
        </div>
    } else {
        return <div className={styles.root}>
            <img src={globeImg} className={styles.icon} />
            <select className={styles.selectElement} onChange={onChange} value={locale}>
                {
                    props.languages.map(lang => {
                        return <option
                            aria-label={lang.longLangName}
                            title={lang.longLangName}
                            value={lang.shortLangName}
                            key={lang.shortLangName}
                        >{lang.shortLangName.toLocaleUpperCase()}</option>;
                    })
                }
            </select>
        </div>;
    }
}

export default connect(null, {})(LanguageSwitch);