import React from "react";
import {useSelector} from "react-redux";
import {getLocale} from "../../redux/selectors";
import {LocaleKeys} from "../../localization/locales";
import {PolicyRu} from "./policy_ru";
import {PolicyEn} from "./policy_en";
import "./privacy_policy_styles.scss";

export const PrivacyPolicy: React.FC = () => {

    const locale = useSelector(getLocale);

    return (
        <section className="privacy_policy_page">
            {
                locale === LocaleKeys.ru ? <PolicyRu /> : <PolicyEn />
            }
        </section>
    );
};
