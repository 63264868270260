export const HOME_LINK = "https://qrpos.group";
export const UPLOADS_LINK = (href: string) => `${HOME_LINK}/uploads/${href}`;

export const GOOGLE_MARKET = "https://play.google.com/store/apps/details?id=ru.ltech.qrposinter&hl=ru&gl=TR";
export const APP_GALLERY = ""; // https://appgallery.huawei.ru/app/C106668263";
export const MOBI_PATH = "http://nkomobi.ru/";
export const PRESENTATION_PATH = "https://qrpos.group/uploads/qrpos_en_09_01_2023.pdf";
export const OFFER_DOC_PATH = "https://qrpos.group/uploads/offerta_qrpos.pdf";
export const POLITICAL_DOC_PATH = "https://qrpos.group/uploads/politika_obrabotki_personalnyh_dannyh.pdf";
export const PAYGINE_POLICY_PATH = "https://qrpos.group/uploads/paygine_politics.pdf";
export const PERSONAL_POLICY_PATH = "https://qrpos.group/uploads/personal_policy.pdf";
export const PAYGINE_SITE_PATH = "https://paygine.ru/";
export const DOWNLOAD_APK_LINK = "https://qrpos.group/uploads/qrpos-latest.apk";
export const LK_LINK = 'https://my.qrpos.group';