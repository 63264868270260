import React from "react";
import styles from "./header.module.scss";
import { Logo } from "src/components/UI/buttons/logo/_logo";
import * as ROUTE_PATH from "src/routes/const_routes";
import { Nav } from "src/components/nav/nav";
import { LanguageSwitch } from "../UI/languageSwitch/languageSwitch";
import { getLanguages } from "../../localization/locales";

export const Header: React.FC = () => {
    return (
        <header className={styles.header}>
            <div className={`container ${styles.top_header_panel_container}`}>
                <div className={styles.headerRow}>
                    <Logo path={ROUTE_PATH.HOME_PATH} />
                    <Nav />
                    <div className={styles.languageSwitcher}>
                        <LanguageSwitch languages={getLanguages()} isMobile={false}/>
                    </div>
                </div>
            </div>
        </header>
    );
};
