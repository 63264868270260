import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const useNavigation = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const detect = (href: string | undefined) => {
        return location.pathname === href ? "active" : "";
    };

    useEffect(() => {
        if (location.hash) {
            const section: HTMLDivElement | null = document.querySelector(
                location.hash,
            );
            section &&
                window.scrollTo({
                    top: section.offsetTop - 100,
                    behavior: "smooth",
                });
            navigate(location.pathname);
        }
    }, [location, navigate]);

    return { detect };
};
