import React from "react";
import { Link } from "react-router-dom";
import { LogoImg } from "src/components/UI/icons/svg_icons";

export const Logo: React.FC<{ path?: string }> = ({ path = "" }) => {
    return (
        <>
            <Link to={path}>
                <LogoImg />
            </Link>
        </>
    );
};
