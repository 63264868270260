import React from "react";
import { Banner } from "src/pages/home/components/banner/banner";
import { HowWork } from "src/pages/home/components/how_work/how_work";
import { HowConnect } from "src/pages/home/components/how_connect/how_connect";
import { HowPayClient } from "src/pages/home/components/how_pay/how_pay";
import { Faq } from "src/pages/home/components/faq/faq";
import { Advantages } from "src/pages/home/components/advantages/advantages";

export const Home: React.FC = () => {
    return (
        <section className="home-page">
            <Banner />
            <Advantages />
            <HowWork />
            <HowPayClient />
            <HowConnect />
            <Faq />
        </section>
    );
};
