import React from "react";
import styles from "./advantages.module.scss";
import { ADVANTAGES, ImageKeys } from "./const_advantages";

import easyImg from "./img/advantages/easy.svg";
import quickImg from "./img/advantages/quick.svg";
import profitableImg from "./img/advantages/profitable.svg";
import safeImg from "./img/advantages/safe.svg";
import accessibleImg from "./img/advantages/accessible.svg";
import { useTranslation } from "react-i18next";

const images: { [ket in ImageKeys]: string } = {
    easy: easyImg,
    quick: quickImg,
    profitable: profitableImg,
    safe: safeImg,
    accessible: accessibleImg,
};

/**
 * Adding empty element to solve issue with flex wrap aliging of the last row
 * See more - https://travishorn.com/some-ways-to-align-the-last-row-in-a-flexbox-grid-720f365dcb16
 * TODO - move to some utils module
 * 
 * @param elements array of JSX elements
 * @returns adjusted array with added empty div
 */
function addEmptyElement(elements: JSX.Element[]) {
    elements.push(<div className={styles.itemEmpty} key="empty"/>);

    return elements;
}

export const Advantages: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.advantages} id="advantages">
            <div className="container">
                <div className="row">
                    <h2>{t('ourAdvantages')}</h2>
                </div>
                <div className="row">
                    <div className={styles.steps}>
                        {addEmptyElement(ADVANTAGES.map((item, idx) => {
                            return (
                                <div className={styles.step} key={idx}>
                                    <img src={images[item.imgKey]} />
                                    <div className={styles.stepInfo}>
                                        <div className={styles.stepName}>
                                            {t(item.nameKey)}
                                        </div>
                                        <div className={styles.stepDesc}>
                                            {t(item.descKey)}
                                        </div>
                                    </div>
                                </div>
                            );
                        }))}
                    </div>
                </div>
                <hr/>
            </div>
        </div>
    );
};
