import React from "react";

import "swiper/css";
import "swiper/css/navigation";
import "./how_pay.styles.scss";
import background_image from "./img/how_pay_background.svg";
import useViewport from "src/hooks/useViewport";
import { useTranslation } from "react-i18next";

function getBackgroundImage() {
    return <div className="background-image-block">
        <img src={background_image} />
        <div className="stage-dot" id="how-pay-stage-dot-one">1</div>
        <div className="stage-dot" id="how-pay-stage-dot-two">2</div>
        <div className="stage-dot" id="how-pay-stage-dot-three">3</div>
    </div>;
}

export const HowPayClient: React.FC = () => {
    const { width } = useViewport();
    const { t } = useTranslation();

    return (
        <div className="how-pay" id="what-look">
            <div className="container">
                {width > 1024 && getBackgroundImage()}
                <div className="row">
                    <h2>{t('howPaymentLooks')}</h2>
                </div>
                {width < 1024 && getBackgroundImage()}
                <div className="flex">
                    <div className="stage-block">
                        <div className="title-block">
                            <div className="num">1</div>
                            <div className="title">
                                {t('buyerScansCode')}
                            </div>
                        </div>

                        <div className="description">
                            {t('buyerScansCodeDescr')}
                        </div>
                    </div>
                    <div className="stage-block">
                        <div className="title-block">
                            <div className="num">2</div>

                            <div className="title">{t('choosesBank')}</div>
                        </div>
                        <div className="description">
                            {t('choosesBankDescr')}
                        </div>
                    </div>

                    <div className="stage-block">
                        <div className="title-block">
                            <div className="num">3</div>
                            <div className="title">{t('confirmsPayment')}</div>
                        </div>
                        <div className="description">
                            {t('confirmsPaymentDescr')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
