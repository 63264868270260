import React from "react";
import "./how_connect.styles.scss";
import { Link } from "react-router-dom";
import * as ROUTE_PATH from "src/routes/const_routes";

import connectImg from "./img/connect_img.png";
import { ReactComponent as RightPointingArrowImg } from "./img/pi-arrow-right.svg";
import { useTranslation } from "react-i18next";

function getStepHeader(index: number, header: string, descriptionContentElement?: JSX.Element | string): JSX.Element {
    return <div className="step-block">
        <div className="step-num">{index}</div>
        <h2 className="header">{header}</h2>
        {descriptionContentElement && <div className="description">{descriptionContentElement}</div>}
    </div>;
}

export const HowConnect: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="how-connect" id={"how-connect"}>
            <div className="right-side-image-block">
                <img src={connectImg} />
            </div>
            <div className="container">
                <div className="left-content-block flex">
                    <h2>{t('howToConntectToService')}</h2>
                    <div className="connect-steps-block">
                        {getStepHeader(1, t('downloadApp'))}
                        {getStepHeader(2,
                            t('signupForApp'),
                            <>
                                <p>{t('forRegistrationYouNeed')}</p>
                                <ul>
                                    <li>{t('yourMobilePhone')}</li>
                                    <li>{t('yourCompanyRequisites')}</li>
                                    <li>{t('paymentRequisites')}</li>
                                </ul>
                            </>
                        )}
                        {getStepHeader(3, t('placeInfoMaterials'), t('downloadAndPlaceMaterials') || '')}
                        <Link className="download-materials-link" to={ROUTE_PATH.PRINT_PATH}>
                            <span>{t('downloadMaterials')}</span>
                            <RightPointingArrowImg/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
