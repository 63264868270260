import React from "react";
import "./banner.styles.scss";
import { BannerHome, BannerHomeEn } from "src/components/UI/icons/svg_icons";
import { GooglePlayButton } from "src/components/UI/buttons/google_play/_google_play";
import { DownloadApkButton } from "src/components/UI/buttons/download_apk/_download_apk";
import useViewport from 'src/hooks/useViewport';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getLocale } from "src/redux/selectors";
import { LocaleKeys } from "src/localization/locales";

export const Banner: React.FC = () => {
    const { width } = useViewport();
    const { t } = useTranslation();
    const locale = useSelector(getLocale);

    return (
        <div className="banner">
            <div className="container">
                <div className="flex">
                    {width > 1024 && <div className="wrapper">
                        {
                            locale === LocaleKeys.en ? <BannerHomeEn /> : <BannerHome />
                        }
                    </div>}
                    <div className="column">
                        <h1>
                            {t('giveYourClientsAbilityToPay')}
                        </h1>
                        
                        <p className="description">
                            {t('installQrposAppAndGetPayments')}
                        </p>
                        {width <= 1024 && <div className="wrapper small-screens-banner-image-wrapper">
                            {
                                locale === LocaleKeys.en ? <BannerHomeEn /> : <BannerHome />
                            }
                        </div>}
                        <div className="button-group">
                            <GooglePlayButton />
                            <DownloadApkButton />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
