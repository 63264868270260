import React, { useMemo, useRef } from "react";
import { MENU_LIST_FOOTER } from "./const_menu";
import { Link } from "react-router-dom";
import { classNames } from "../utils/classess";
import { useTranslation } from "react-i18next";

export const NavigationFooter: React.FC = () => {
    const menuRef = useRef(null);
    const { t } = useTranslation();

    const renderNavigation = useMemo(() => {
        return (
            <ul className={classNames("footer__menu__items")}>
                {MENU_LIST_FOOTER.map((item, index) => {
                    if (item.newPage) {
                        return (
                            <li className="footer__menu__item" key={index}>
                                <a
                                    href={item.href}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t(item.textKey || '') || ''}
                                </a>    
                            </li>
                        );
                    }
                    return (
                        <li className="footer__menu__item" key={index}>
                            <Link to={item.href || ""}>{t(item.textKey || '') || ''}</Link>
                        </li>
                    );
                })}
            </ul>
        );
    }, [t, MENU_LIST_FOOTER]);
    return (
        <div className="footer__menu" ref={menuRef}>
            <div className={classNames("nav")}>{renderNavigation}</div>
        </div>
    );
};
