type TQuestion = {
    nameKey: string;
    answerKey: string;
};

export const QUESTIONS: TQuestion[] = [
    {
        nameKey: "howToVerifyPaymentSuccess",
        answerKey: "howToVerifyPaymentSuccessAnswer",
    },
    {
        nameKey: "whenWillIGetMoney",
        answerKey: "whenWillIGetMoneyAnswer",
    },
    {
        nameKey: "requisitesChangedQuestion",
        answerKey: "requisitesChangedAnswer",
    },
    {
        nameKey: "whichEquipmentIsRequiredQuestion",
        answerKey: "whichEquipmentIsRequiredAnswer",
    },
    {
        nameKey: "whatBuyerShouldHaveToPayQuestion",
        answerKey: "whatBuyerShouldHaveToPayAnswer",
    },
    {
        nameKey: "doesBankMakesDifferenceQuestion",
        answerKey: "doesBankMakesDifferenceAnswer",
    },
    {
        nameKey: "whatIsSBPQuestion",
        answerKey: "whatIsSBPAnswer",
    },
    {
        nameKey: "IsReceiptRequiredQuestion",
        answerKey: "IsReceiptRequiredAnswer",
    },
    {
        nameKey: "howToRefundQuestion",
        answerKey: "howToRefundAnswer",
    },
    {
        nameKey: "howToGetRegistryQuestion",
        answerKey: "howToGetRegistryAnswer",
    },
    {
        nameKey: "howQRProtectedQuestion",
        answerKey: "howQRProtectedAnswer",
    },
    {
        nameKey: "whatIfIRemovedAppQuestion",
        answerKey: "whatIfIRemovedAppAnswer",
    },
    {
        nameKey: "whatIfIPhoneChangedQuestion",
        answerKey: "whatIfIPhoneChangedAnswer",
    },
    {
        nameKey: "whatIfCompanyChangedQuestion",
        answerKey: "whatIfCompanyChangedAnswer",
    },
    {
        nameKey: "permissionsQuestion",
        answerKey: "permissionsAnswer",
    },
    {
        nameKey: "registeredAsEmployeeQuestion",
        answerKey: "registeredAsEmployeeAnswer",
    },
    {
        nameKey: "howToDeleteAccountQuestion",
        answerKey: "howToDeleteAccountAnswer",
    },
];
