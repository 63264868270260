import * as img from "src/pages/print/img";
import { UPLOADS_LINK } from "src/constants/link";
import { LocaleKeys } from "src/localization/locales";

type TContentParams = {
    nameKey?: string;
    paramsKeys?: string[];
};

export const contentParams: TContentParams[] = [
    {
        nameKey: "format",
        paramsKeys: ["16087mm","160230mm"],
    },
    {
        nameKey: "oneSidePrinting",
        paramsKeys: [
            "onSelfAdhesiveFilm",
            "onCoatedPaper",
            "laminationRecommended",
        ],
    },
    {
        nameKey: "archivesInclude",
        paramsKeys: [
            "editableLayout",
            "printedLayouts",
            "fonts",
        ],
    },
];

type TDefaultCards = {
    imgKey?: string;
    linkKey?: string;
    paramKey?: string;
    type?: "default" | "small" | "big";
    double?: TDefaultCards[];
};

export function getLink(linkKey: string, locale: string) {
    return UPLOADS_LINK(`print/${locale}/${linkKey}`);
}

export const contentCards: TDefaultCards[] = [
    {
        imgKey: "client1",
        paramKey: "160230mm",
        linkKey: "qrpos_sticker_160x230_01.zip",
    },
    {
        imgKey: "client2",
        paramKey: "160230mm",
        linkKey: "qrpos_sticker_160x230_02.zip",
    },
    {
        imgKey: "client3",
        paramKey: "160230mm",
        linkKey: "qrpos_sticker_160x230_03.zip",
    },
    {
        imgKey: "client4",
        paramKey: "160230mm",
        linkKey: "qrpos_sticker_sbp_160x230_01.zip",
    },
    {
        imgKey: "client5",
        paramKey: "160230mm",
        linkKey: "qrpos_sticker_sbp_160x230_02.zip",
    },
    {
        double: [
            {
                type: "small",
                imgKey: "client6",
                paramKey: "16087mm",
                linkKey: "qrpos_sticker_sbp_160x87_01.zip",
            },
            {
                type: "small",
                imgKey: "client7",
                paramKey: "16087mm",
                linkKey: "qrpos_sticker_sbp_160x87_02.zip",
            },
        ],
    },
];
