import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../utils/classess";

export const NavItem: React.FC<{
    href?: string;
    text?: string;
    onClick?: Function;
    active?: string;
    newPage?: boolean;
    className?: string;
}> = ({ href = "", text, onClick, active, newPage , className}) => {
    const handle = () => (onClick ? onClick() : null);
    if (newPage) {
        return (
            <li
                className={"menu__item " + className}
            >
                <a
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handle}
                >
                    {text}
                </a>
            </li>
        );
    }
    return (
        <li className="menu__item">
            <Link to={href} className={classNames(active)} onClick={handle}>
                {text}
            </Link>
        </li>
    );
};
