import { getDefaultLocale } from "src/localization/locales";
import { Actions } from "./actions";
import { IAction } from "./IAction";
import { IStore } from "./IStore";

const defaultStore = {
    locale: getDefaultLocale(),
};

export function mainReducer(state: IStore | undefined = defaultStore, action: IAction): IStore {
    switch (action.type) {
        case Actions.SET_LOCALE: {
            return { ...state, locale: action.payload };
        }
        default:
            return state;
    }
}