import { getDefaultLocale, getLocales, LocaleKeys } from "./locales"
import i18n from "src/i18n";
import { Dispatch } from "react";
import { IAction } from "src/redux/IAction";
import { Actions } from "src/redux/actions";

export function setUpLocale(location: string, dispatch: Dispatch<IAction>) {
    const locales = getLocales() as any;
    const splittedUrl = location.split('/');
   
    if (splittedUrl.length > 0) {
        const locale = splittedUrl[1];
        if (locales[locale]) {
            i18n.changeLanguage(locale).then(() => {
                dispatch({
                    type: Actions.SET_LOCALE,
                    payload: locale,
                });
            });

            return locale;
        } else {
            return getDefaultLocale();
        }
    }
}

export function getImageKeyByLocale(imageKey: string, locale: string) {
    if (imageKey === LocaleKeys.ru) {
        return imageKey;
    } else {
        return `${imageKey}${locale}`;
    }
}