import React from "react";
import styles from "./apk.module.scss";
import { DOWNLOAD_APK_LINK } from "src/constants/link";
import { useTranslation } from "react-i18next";

export const DownloadApkButton: React.FC = () => {
    const { t } = useTranslation();
    return (
        <a href={DOWNLOAD_APK_LINK} target={"_blank"} className={styles.apk}>
            {t("downloadApkText")}
        </a>
    );
};
