import React from "react";
import { CheckMarkSuccess } from "src/components/UI/checkmark/success/checkmark_success";
import styles from "./popup.module.scss";
interface PopupFeedBackProps {
    onClose?: Function;
}
export const PopupFeedBack: React.FC<PopupFeedBackProps> = ({ onClose }) => {
    return (
        <div className={styles.container}>
            <div
                className={styles.closeButton}
                onClick={() => (onClose ? onClose() : null)}
            >
                <i className="pi pi-times"></i>
            </div>
            <CheckMarkSuccess className={styles.checkMark} />
            <h3 className={styles.message}>Письмо успешно отправлено</h3>
        </div>
    );
};
