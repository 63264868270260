import * as ROUTE_PATH from "src/routes/const_routes";
import * as link from "src/constants/link";
import * as route from "../../routes/const_routes";

export type TMenu = {
    textKey?: string;
    href?: string;
    newPage?: boolean;
    className?: string;
    appendLocale ?: boolean;
};


export const MENU_LIST_DESKTOP: TMenu[] = [
    {
        textKey: 'advantages',
        href: ROUTE_PATH.ADVANTAGES_PATH,
    },
    {
        textKey: 'howWorks',
        href: ROUTE_PATH.HOW_WORK_PATH,
    },
    {
        textKey: 'howToConntect',
        href: ROUTE_PATH.HOW_CONNECT_PATH,
    },
    {
        textKey: 'howPaymentLooks',
        href: ROUTE_PATH.WHAT_LOOK_PATH,
    },
    {
        textKey: 'faq',
        href: ROUTE_PATH.QUESTIONS_PATH,
    },
    {
        textKey: 'lk',
        href: link.LK_LINK,
        newPage: true,
        className: 'lk-link',
        appendLocale: true
    },
];

export const MENU_LIST_MOBILE: TMenu[] = [
    {
        textKey: 'lk',
        href: link.LK_LINK,
        newPage: true,
        className: 'lk-link',
        appendLocale: true,
    },
    {
        textKey: 'advantages',
        href: ROUTE_PATH.ADVANTAGES_PATH,
        newPage: false,
    },
    {
        textKey: 'howWorks',
        href: ROUTE_PATH.HOW_WORK_PATH,
        newPage: false,
    },
    {
        textKey: 'howToConntect',
        href: ROUTE_PATH.HOW_CONNECT_PATH,
        newPage: false,
    },
    {
        textKey: 'howPaymentLooks',
        href: ROUTE_PATH.WHAT_LOOK_PATH,
        newPage: false,
    },
    {
        textKey: 'faq',
        href: ROUTE_PATH.QUESTIONS_PATH,
        newPage: false,
    },
    {
        textKey: 'printMaterials',
        href: ROUTE_PATH.PRINT_PATH,
        newPage: false,
    },
    {
        textKey: "personalPolicy",
        href: route.PRIVACY_POLICY_PATH,
        newPage: false,
    },
    {
        textKey: 'downloadPresentation',
        href: link.PRESENTATION_PATH,
        newPage: true,
    },
    {
        textKey: 'aboutCompany',
        href: "",
        newPage: true,
    },
];
