import React from "react";
import { HOME_LINK } from "src/constants/link";

export const Head = () => {
    return (
        <>
            {/*<meta charSet="utf-8" />*/}
            {/*<title>QRpos</title>*/}
            {/*<link rel="canonical" href={HOME_LINK} />*/}
        </>
    );
};
