import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "./how_work.styles.scss";
import { SLIDER } from "./const_slider";
import useViewport from 'src/hooks/useViewport';

import { ReactComponent as ArrowIcon } from "./img/arrow.svg";
import { ReactComponent as TriangleCloud } from "./img/cloud-triangle.svg";
import { useTranslation } from "react-i18next";

import phone1 from "./img/smrtphone_1.png";
import phone2 from "./img/smrtphone_2.png";
import phone3 from "./img/smrtphone_3.png";
import phone4 from "./img/smrtphone_4.png";
import phone5 from "./img/smrtphone_5.png";
import phone1en from "./img/en/smrtphone_1.png";
import phone2en from "./img/en/smrtphone_2.png";
import phone3en from "./img/en/smrtphone_3.png";
import phone4en from "./img/en/smrtphone_4.png";
import phone5en from "./img/en/smrtphone_5.png";
import { useSelector } from "react-redux";
import { getLocale } from "src/redux/selectors";
import { LocaleKeys } from "src/localization/locales";

const phoneImages: { [key: string]: string } = {
    phone1,
    phone2,
    phone3,
    phone4,
    phone5,
    phone1en,
    phone2en,
    phone3en,
    phone4en,
    phone5en,
};

const large1280MonitorScreenWidth = 1140;

/**
 * Returns next slider number.
 * Considers looping
 * @param forward should we go forward - one slide ahead; if false - backward
 * @param currentSliderNumber current slider number
 * @param slidesCount count of slides
 * @returns 
 */
function getNextSliderNumber(forward: boolean, currentSliderNumber: number, slidesCount: number): number {
    let newSliderNumber = forward ? currentSliderNumber + 1 : currentSliderNumber - 1;
    if (newSliderNumber >= slidesCount) {
        return 0;
    }

    if (newSliderNumber < 0) {
        return slidesCount - 1;
    }

    return newSliderNumber;
}

function getImage(imageNumber: number, locale: string) {
    return phoneImages[`phone${imageNumber}${locale === LocaleKeys.ru ? '': locale}`];
}

function getSlider(
    setSwiper: React.Dispatch<React.SetStateAction<null>>,
    setCurrentSlide: React.Dispatch<React.SetStateAction<number>>,
    locale: string,

): JSX.Element {
    return <div className="how_work__slider">
        <Swiper
            modules={[Autoplay, Navigation]}
            className="slider-items"
            centeredSlides={true}
            loop={true}
            autoplay={{
                delay: 8000,
                disableOnInteraction: false,
            }}
            navigation={true}
            onSwiper={(swiper) => setSwiper(swiper as any)}
            onRealIndexChange={(swiper) => setCurrentSlide(swiper.realIndex)}
        >
            {SLIDER.map((item, index) => {
                return (
                    <SwiperSlide
                        className="slider-item"
                        key={index}
                    >
                        <img
                            src={getImage(item.imgNumber, locale)}
                            alt={index.toString()}
                        />
                    </SwiperSlide>
                );
            })}

        </Swiper>
    </div>
}

export const HowWork: React.FC = () => {
    const [swiper, setSwiper] = React.useState(null);
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const { width } = useViewport();
    const { t } = useTranslation();
    
    const locale = useSelector(getLocale);

    return (
        <div className="how_work" id="how-work">
            <div className="container how-work-grid">
                {width > 800 && getSlider(setSwiper, setCurrentSlide, locale)}
                <div className="description-block">
                    <h2>{t('howWorks')}</h2>
                    <p className="how_work__title">
                        {t('qrPosWorkIsSimilar')}
                    </p>
                    {width <= 800 && getSlider(setSwiper, setCurrentSlide, locale)}
                    <div className="description-cloud">
                        <div className="step">{t('step')} {currentSlide + 1}</div>
                        <div className="description">
                            {t(SLIDER[currentSlide].descriptionKey)}
                        </div>
                        <TriangleCloud className="triangle-cloud-image"/>
                    </div>
                    <div className="nav-buttons not-selectable">
                        <button onClick={() => {
                            (swiper as any)?.slidePrev();
                        }}
                        >
                            <ArrowIcon/>
                        </button>
                        <button onClick={() => {
                            (swiper as any)?.slideNext();
                        }}
                        >
                            <ArrowIcon/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
