import * as ROUTE_PATH from "src/routes/const_routes";
import * as link from "src/constants/link";
import * as route from "src/routes/const_routes";

type TMenu = {
    textKey?: string;
    href?: string;
    newPage?: boolean;
};

export const MENU_LIST_FOOTER: TMenu[] = [
    {
        textKey: "advantages",
        href: ROUTE_PATH?.ADVANTAGES_PATH,
        newPage: false,
    },
    {
        textKey: "howWorks",
        href: ROUTE_PATH?.HOW_WORK_PATH,
        newPage: false,
    },
    {
        textKey: "howToConntect",
        href: ROUTE_PATH?.HOW_CONNECT_PATH,
        newPage: false,
    },
    {
        textKey: "howPaymentLooks",
        href: ROUTE_PATH?.WHAT_LOOK_PATH,
        newPage: false,
    },
    {
        textKey: "faq",
        href: ROUTE_PATH?.QUESTIONS_PATH,
        newPage: false,
    },
    {
        textKey: "printMaterials",
        href: ROUTE_PATH.PRINT_PATH,
        newPage: false,
    },
    // {
    //     textKey: "offer",
    //     href: link.OFFER_DOC_PATH,
    //     newPage: true,
    // },
    // {
    //     textKey: "personalDataPolicyAgreement",
    //     href: link.POLITICAL_DOC_PATH,
    //     newPage: true,
    // },
    {
        textKey: "downloadPresentation",
        href: link.PRESENTATION_PATH,
        newPage: true,
    },
    {
        textKey: "aboutCompany",
        href: "",
        newPage: true,
    },
    {
        textKey: "personalPolicy",
        href: route.PRIVACY_POLICY_PATH,
        newPage: false,
    },
];
