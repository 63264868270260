import React from "react";
import styles from "./brand_book.module.scss";
import { LazyImage } from "src/components/UI/img";
import { Spinner } from "src/components/UI/spinner";
import "primeicons/primeicons.css";
import { classNames } from "../../../utils/classess";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

interface CardBrandBookProps {
    info?: string;
    link?: string;
    preview?: string;
    type?: "default" | "small" | "big";
}

export const CardBrandBook: React.FC<CardBrandBookProps> = ({
    info,
    link,
    preview,
    type = "default",
}) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(styles.card, styles[type])}>
            <div className={styles.cardImage}>
                <LazyImage src={preview} loadComponent={<Spinner />} />
            </div>
            <a href={link} className={styles.cardLink}>
                <div className={styles.cardLink__title}>
                    <i className="pi pi-download"></i>{t('downloadMaterials')}
                </div>
                <div className={styles.cardLink__info}>{info}</div>
            </a>
        </div>
    );
};

interface CardsBrandBookProps {
    children?: React.ReactNode;
}

export const CardsBrandBook: React.FC<CardsBrandBookProps> = ({ children }) => {
    return <div className={styles.cards}>{children}</div>;
};
export const CardDoubleBrandBook: React.FC<CardsBrandBookProps> = ({
    children,
}) => {
    return <div className={styles.doubleCard}>{children}</div>;
};
