/**
 * Хук для обработки события клика вне области
 */
import { useState, useEffect, RefObject } from "react";

export const useDetectClick = (
    ref: RefObject<HTMLElement>,
    refButton?: RefObject<HTMLElement>,
) => {
    const [isActive, setIsActive] = useState<boolean>(false);

    useEffect(() => {
        const pageClickEvent = (e: any) => {
            // Обработка клика если вне области курсор
            let setClick = true;
            if (ref.current !== null && !ref.current.contains(e.target)) {
                setClick = false;
            } else {
                return;
            }
            if (refButton) {
                if (
                    refButton?.current !== null &&
                    !refButton?.current.contains(e.target)
                ) {
                    setClick = false;
                } else {
                    return;
                }
            }
            setIsActive(setClick);
        };

        // Если активно, то навешиваем события клика
        if (isActive) {
            document.addEventListener("mousedown", pageClickEvent);
        }

        return () => {
            document.removeEventListener("mousedown", pageClickEvent);
        };
    }, [isActive, ref, refButton]);

    const setActive = () => {
        setIsActive(!isActive);
    };

    const setClose = () => {
        setIsActive(false);
    };

    return { isActive, setActive, setClose };
};
